import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import { View, Image, Dimensions, Button, Text, TouchableOpacity, StyleSheet, TextInput, ActivityIndicator, Platform } from 'react-native'
import * as DocumentPicker from 'expo-document-picker';
import { Color } from './../utils/color';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const UploadLeadScoring = ({ type, authCode, getData }) => {
  
    const [success, setSuccess] = useState('')
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [fileSize, setFileSize] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    const [loading, setLoading] = useState(false)
    const [gettingFile, setGettingFile] = useState(false)


    const fileInputRef = useRef(null);

    const getFile = async()=>{
      setSuccess('')
      setErrorMessage('')
      let result = await DocumentPicker.getDocumentAsync({
        type: ['text/plain']
        })

      if(result.file.type == 'text/plain'){
        setFile(()=>result)
      } else {
        setErrorMessage('Error file type not supported.')
        return
      }
    }

    
    const removeFile = async()=>{
      setSuccess('')
      setFile()
      setFileName('')
      setFileSize('')
      setErrorMessage('')
    }

    useEffect(()=>{
     if(file){
      setFileName(file.name)

      const MB = 1024 * 1024
      const KB = 1024;

      const fileSizeInMB = (file.size / MB).toFixed(2)

      if (file.size < MB) {
        // If file size is less than 1 MB, calculate size in KB
        const fileSizeInKB = (file.size / KB).toFixed(2);
        setFileSize(`${fileSizeInKB} KB`);
    } else {
        // Otherwise, return file size in MB
        setFileSize(`${fileSizeInMB} MB`);
    }
     } return
    },[file])


    const postRequest = async () => {
        setLoading(true)

        let result = file
        
        const data = new FormData();
        data.append('report', result.file);

        fetch(`https://cxml.cxsphere.com/reporting/leads`, {
          method: "POST",
          headers: {
            'Authorization': authCode
          },
          body: data
        })
        .then(async(res) => res.json())
        .then((res)=>{
            console.log(res)
            if(res.detail){
                setErrorMessage(`There was error parsing the file:${res.detail}`)
            }  
          const result = res.completion
          getData({result, fileName})
          setSuccess('')
          setFile()
          setFileName('')
          setLoading(false)
        })
        .catch(()=>{
          console.log('fetch request not made')
          setLoading(false)
        })
    }

    const findType = (type) => {
        if(type == 'lead-score-uploader'){
          return postRequest()
        }
    }


    return (
      <View style={{ 
      width: Dimensions.get('window').width * 0.9,
      maxWidth: 700,
      marginLeft:15,
      marginTop: 15, 
      marginBottom: 15, 
      height: file ? 150 : 150, 
      borderStyle: 'dashed',
      borderColor: Color.DarkGreen,
      backgroundColor:'#fff', 
      borderRadius:10,
      borderRightWidth:1, 
      borderLeftWidth:1, 
      borderBottomWidth:1, 
      borderTopWidth:1,
      justifyContent: (file ? 'flex-start' : 'center'),
      alignItems:(file ? 'center' : 'center'),      
    }}>

      {loading && 
      <View style={{flexDirection: 'row', marginTop: 10}}>
        <ActivityIndicator/>
        <Text style={{marginLeft: 10}}>Please wait while your data is being processed</Text>
      </View>}

          <View>  
            {!file ? 
            (<View style={{width: 150, alignItems: 'center', justifyContent: 'center'}}>
            {Platform.OS !== 'ios' || Platform.OS !== 'android' ? 
          
              <>
                <input type="file" accept=".txt" ref={fileInputRef} style={{display: 'none'}} />
                <Button onPress={() => getFile()}
                    disabled={gettingFile}
                    backgroundColor={Color.DarkGreen}
                    color={Color.DarkGreen}
                    title={gettingFile ? "Loading..." : "Add a new File"}>
                  </Button>
              </>
              
            :

              <Button onPress={() => getFile()}
                disabled={gettingFile}
                backgroundColor={Color.DarkGreen}
                color={Color.DarkGreen}
                title={gettingFile ? "Loading..." : "Add a new File"}>
              </Button>
            }
              <Text style={{ fontSize: 10, color: 'black'}}>file format .txt</Text>
            </View> ) :
            (<View style={{justifyContent: 'space-between'}}>
              <TouchableOpacity style={styles.card} activeOpacity={.6}>
                <Text style={{maxWidth: '40%', fontWeight: '500', marginRight: 30}} lineBreakMode='tail' numberOfLines={1}>{fileName}</Text>
                <Text style={{fontWeight: '500', width: 100}} numberOfLines={1}>{fileSize}</Text>
                <View style={{flexDirection: 'row'}}>
                  <TouchableOpacity 
                  disabled={loading}
                  onPress={()=>removeFile()}>
                    <MaterialCommunityIcons name="delete" size={20} color="black" style={{marginLeft: 20}} />
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </View>)}
          </View>

            {file && 
            <View style={{alignSelf: 'center', marginTop: 0}}>

              <Button
                disabled={loading} 
                onPress={() => findType(type)}
                backgroundColor={Color.DarkGreen}
                color={Color.DarkGreen}
                title="Upload File">
              </Button>
            </View>
            }

            {!file && success.length > 0 &&
            <View style={{backgroundColor: '#e3e3e3', position: 'absolute', bottom: 200, padding: 10, borderRadius: 8}}>
            <Text>{success}</Text>
            </View>}

            {errorMessage.length > 0 && 
            <View style={{backgroundColor: '#e3e3e3', bottom: 100, padding: 10, borderRadius: 8, borderWidth: 1, borderColor: 'red', alignSelf: 'center', opacity: .8}}>
            <Text>{errorMessage}</Text>
            </View>}
        </View>
    );
    
  };

  export default UploadLeadScoring;

  const styles = StyleSheet.create({
    card:{
      flexDirection: 'row', 
      padding: 10, 
      justifyContent: 'space-between', 
      backgroundColor: 'white', 
      borderRadius: 4, 
      borderWidth: 1, 
      borderColor: '#e3e3e3',
      margin: 10, 
      maxWidth: 400, 
      // maxWidth: '90%',
      minHeight: 50,
      alignItems: 'center'
    }
  })
